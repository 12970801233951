import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin';
import type { ReferenceInputProps } from 'react-admin';

import { ResourceKey } from 'constants/index';

import { ApmLocationOption } from './ApmLocationOption';
import { LocationCard } from './LocationCard';

export interface ApmSearchProps extends Omit<ReferenceInputProps, 'reference' | 'children' | 'source' | 'filter'> {
    source?: string;
}

export const ApmSearch = ({ source = 'location.id', validate, ...props }: ApmSearchProps) => {
    const translate = useTranslate();

    return (
        <>
            <ReferenceInput
                {...props}
                source={source}
                reference={ResourceKey.LOCATION}
                filter={{ type: 'apm', includeApmReference: true }}
                queryOptions={{ meta: { additionalFilters: { includeApmReference: true } } }}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <>
                    <AutocompleteInput
                        label={translate('parcel.delivery.location.label')}
                        optionText={<ApmLocationOption />}
                        inputText={ApmLocationOption.inputText(translate)}
                        matchSuggestion={ApmLocationOption.matchSuggestion}
                        fullWidth
                        validate={validate}
                    />

                    <LocationCard />
                </>
            </ReferenceInput>
        </>
    );
};
