import { Stack, Typography } from '@mui/material';
import { FormDataConsumer, RadioButtonGroupInput, required, useTranslate } from 'react-admin';

import { AddressForm } from 'modules/ui';

import { ApmSearch } from '../../ApmSearch';
import { DeliveryPartnerForm } from '../../DeliveryPartnerForm';
import { OrderAddressContact } from '../../OrderAddressContact';

export interface EditDeliveryAddressStepProps {
    canEditContact: boolean;
    canEditAddress: boolean;
}

export const EditDeliveryAddressStep = ({ canEditContact, canEditAddress }: EditDeliveryAddressStepProps) => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" component="h1">
                {translate('parcel.delivery.edit.title')}
            </Typography>

            {canEditAddress && (
                <Stack mb={3}>
                    <RadioButtonGroupInput
                        source="addresses.toLocation.type"
                        label={translate('delivery.type')}
                        choices={['location', 'address'].map(addressType => ({
                            id: addressType,
                            name: `delivery.type.${addressType}`,
                        }))}
                    />

                    <FormDataConsumer>
                        {({ formData }) => {
                            const location = formData.addresses.toLocation;

                            return location.type === 'location' ? (
                                <ApmSearch
                                    source="addresses.toLocation.id"
                                    validate={required(translate('parcel.delivery.location.error.required'))}
                                />
                            ) : (
                                <>
                                    <AddressForm source="addresses.toLocation" />
                                </>
                            );
                        }}
                    </FormDataConsumer>
                </Stack>
            )}

            {canEditContact && <OrderAddressContact sourcePrefix="recipient" />}

            <FormDataConsumer>
                {({ formData }) => {
                    const location = formData.addresses.toLocation;

                    return (
                        location.type === 'address' && (
                            <DeliveryPartnerForm
                                locationName="toLocation"
                                parcelIdSource="destinationDeliveryParcelId"
                            />
                        )
                    );
                }}
            </FormDataConsumer>
        </>
    );
};
