import type { EditApmDetail } from 'modules/apm/types';

export const apmEditPayloadKeys: Array<keyof EditApmDetail> = [
    'name',
    'title',
    'identifier',
    'description',
    'internalNote',
    'location',
    'photo',
    'compartments',
    'depot',
    'state',
    'stateChangeReason',
    'availabilityProbSmall',
    'availabilityProbMedium',
    'availabilityProbLarge',
    'columnsOrder',
    'nearbyApmLocationId',
];
