import {
    Show,
    TextField,
    useTranslate,
    ReferenceField,
    Labeled,
    Tab,
    TabbedShowLayout,
    FunctionField,
} from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import type { ShowProps } from 'react-admin';
import { Typography, Box } from '@mui/material';

import { ResourceKey } from 'constants/index';
import { SeoHead } from 'modules/ui';
import { AddressField, LatLngField, ImageField } from 'modules/ui';
import { PermissionAction } from 'modules/roles';
import { useHasAccess } from 'modules/auth';
import { ConnectedLanesList } from 'modules/lanes';
import { LocationCardData } from 'modules/parcels/components/ApmSearch/LocationCard';

import type { ApmDetail as ApmDetailType } from 'modules/apm/types';
import type { ExtendedLocation } from 'modules/parcels/types';

import { ApmState } from '../ApmState';
import { ApmHeader } from '../ApmHeader';

import { ApmAvailabilityInfo } from '../ApmAvailabilityInfo';
import { ShowLcdTextButton } from '../ShowLcdTextButton';

import { ApmCompartments } from '../ApmCompartments';
import { ApmHistoryLog } from '../ApmHistoryLog';
import { ReportIssueButton } from '../ReportIssueButton';

import * as Styled from './ApmDetail.styles';
import { SyncLockerButton } from '../SyncLockerButton';

export const ApmDetail = (props: ShowProps) => {
    const translate = useTranslate();

    const access = {
        showLcdText: useHasAccess(ResourceKey.APM, PermissionAction.SHOW_LCD_TEXT),
        reportIssue: useHasAccess(ResourceKey.APM, PermissionAction.REPORT_APM_ISSUE),
        syncLocker: useHasAccess(ResourceKey.APM, PermissionAction.REFRESH_APM_CONFIGURATION),
    };

    return (
        <>
            <SeoHead title={translate('apm.list.title')} />
            <Show>
                <BoxedShowLayout>
                    <Styled.Container display="flex">
                        <Styled.DataColumn>
                            <ApmHeader />

                            <Box mb={2}>
                                <ApmAvailabilityInfo />
                            </Box>

                            <Styled.PropertiesContainer>
                                <Labeled>
                                    <TextField source="name" label={translate('apm.list.name')} />
                                </Labeled>
                                <Labeled label={translate('apm.list.status')}>
                                    <ApmState source="state" />
                                </Labeled>
                                <Labeled>
                                    <TextField source="title" label={translate('apm.edit.titleField')} />
                                </Labeled>
                                <Labeled>
                                    <TextField source="identifier" label={translate('apm.list.identifier')} />
                                </Labeled>
                                <Labeled>
                                    <TextField
                                        source="description"
                                        label={translate('apm.edit.description')}
                                        fullWidth
                                    />
                                </Labeled>
                                <Labeled>
                                    <TextField
                                        source="internalNote"
                                        label={translate('apm.edit.internalNote')}
                                        fullWidth
                                    />
                                </Labeled>
                                <Labeled>
                                    <FunctionField
                                        source="columnsOrder"
                                        label={translate('apm.edit.columnsOrder')}
                                        maxWidth={500}
                                        render={(record: ApmDetailType) => record?.columnsOrder?.replace(/,/g, ', ')}
                                    />
                                </Labeled>
                            </Styled.PropertiesContainer>

                            <Typography variant="h6" component="h3">
                                {translate('apm.edit.location')}
                            </Typography>

                            <Labeled>
                                <TextField label="apm.location.id" source="location.id" />
                            </Labeled>
                            <AddressField source="location" />
                            <LatLngField source="location.coordinates" />

                            <BoxedShowLayout>
                                <RaBox>
                                    <ReferenceField
                                        source="depot"
                                        reference={ResourceKey.LOCATION}
                                        label={translate('users.depot')}
                                        link={false}
                                    >
                                        <TextField source="name" />
                                    </ReferenceField>
                                </RaBox>
                            </BoxedShowLayout>

                            <Typography variant="h6" component="h3" mb={1}>
                                {translate('apm.edit.nearbyApm')}
                            </Typography>
                            <ReferenceField
                                source="nearbyApmLocationId"
                                reference={ResourceKey.LOCATION}
                                emptyText={translate('apm.nearbyApm.notSet')}
                            >
                                <FunctionField
                                    render={(loc: ExtendedLocation) => (
                                        <Box maxWidth={400}>
                                            <LocationCardData location={loc} />
                                        </Box>
                                    )}
                                />
                            </ReferenceField>
                        </Styled.DataColumn>
                        <Styled.ImageColumn>
                            <Styled.ButtonsContainer>
                                {access.reportIssue && <ReportIssueButton />}
                                {access.showLcdText && <ShowLcdTextButton />}
                                {access.syncLocker && <SyncLockerButton />}
                            </Styled.ButtonsContainer>
                            <ImageField source="photo" />
                        </Styled.ImageColumn>
                    </Styled.Container>

                    <TabbedShowLayout>
                        <Tab label={'apm.edit.compartments'}>
                            <ApmCompartments />
                        </Tab>
                        <Tab label={'lanes.list.title'}>
                            <ConnectedLanesList />
                        </Tab>
                        <Tab label={'apm.stateHistory.title'}>
                            <ApmHistoryLog />
                        </Tab>
                    </TabbedShowLayout>
                </BoxedShowLayout>
            </Show>
        </>
    );
};
