import { useRecordContext, useTranslate } from 'react-admin';
import { Typography, Box } from '@mui/material';

import { ApmSearch } from 'modules/parcels/components/ApmSearch';
import type { ApmDetail } from 'modules/apm/types';

export const NearbyApm = () => {
    const translate = useTranslate();
    const record = useRecordContext<ApmDetail>();

    return (
        <>
            <Typography variant="h6" component="h3">
                {translate('apm.edit.nearbyApm')}
            </Typography>
            <Box maxWidth={600} sx={{ width: '100%' }}>
                <ApmSearch
                    source="nearbyApmLocationId"
                    validate={(apmLocationId: string | null) => {
                        if (apmLocationId === record?.location?.id) {
                            return translate('apm.edit.nearbyApm.validation.cannotAssignSameLocation');
                        }
                    }}
                />
            </Box>
        </>
    );
};
